:root {
    --color-1: #6366f1;
    --color-1-hover: #4338ca;
    --color-2: #06b6d4;
    --color-2-hover: #0891b2;
    --text-color: #312e81;
    --status-btn-bg: #f8fafc;
    --status-btn-bg-hover: #f1f5f9;
}

.wizard {
    margin: 3rem auto;
    max-width: 500px;
    background: white;
    border-radius: 1rem;
    padding: 2rem;
}

.form-input {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: .5rem;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, .1);
    padding: 1rem;
    box-sizing: border-box;
    color: var(--text-color);
    transition: ease-in-out .3s all;
}

.form-input::placeholder {
    color: #cbd5e1;
}

.form-input:focus {
    outline: none;
    border-color: var(--color-1);
}

.pagination .btn:focus-within,
.form-input:focus-within {
    box-shadow: #f8fafc 0px 0px 0px 2px, #c7d2fe 0px 0px 0px 6px, #0000 0px 1px 2px 0px;
}

textarea.form-input {
    min-height: 150px;
}

.pagination .btn {
    border: 0;
    background-color: var(--color-1);
    padding: 1rem;
    border-radius: 25px;
    color: white !important;
    cursor: pointer;
}

.pagination .btn[disabled] {
    opacity: .5;
    pointer-events: none;
}

.pagination .btn:hover {
    background: var(--color-1-hover);
    transition: ease-in-out .3s all;
}

.pagination .btn-submit {
    background-color: var(--color-2);
}

.pagination .btn-submit:hover {
    background-color: var(--color-2-hover);
}

.pagination {
    margin-top: 1rem;
    display: block;
    align-items: center;
    justify-content: center;
}

.pagination .btn {
    width: 100%;
    text-align: center;
    margin: 0 6px;
}

.tab-status {
    display: flex;
    align-items: center;
}

.tab-status span.nav-link {
    cursor: pointer;
    color: black;
    appearance: none;
    background: var(--status-btn-bg);
    border: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-status span.nav-link.active {
    background-color: var(--color-2);
    color: white !important;
}

.tab-status span.nav-link.disabled {
    color: lightgrey !important;
}

.hidden {
    display: none;
}


form h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}